.App {
  text-align: center;
}

.App-logo {
  height: 4vmin;
  min-height: 2em;
  max-height: 6em;
  pointer-events: none;
}

.Client-logo {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  overflow: hidden;
}

.admin-schemaform-modal {
  object-fit: scale-down;
  overflow: auto;
}

.modal-standard {
  width: 650px;
  max-width: 650px !important;
}

.modal-large {
  width: 850px;
  max-width: 850px !important;
}

.hr_bold {
  border: 1px solid gray;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 60s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-input-fixed {
  width: 75%;
}

.array-item-list {
  display: block;
  /* column, column-reverse, row, row-reverse */
  /* flex-direction: column-reverse; */
}

.control-label {
  font-size: medium;
  display: block;
}

.control-label i.asterisk {
  font-size: x-small;
  vertical-align: super;
}

.checkbox span {
  /* spacing between the input checkbox and the label */
  /* background-color: aquamarine; */
  margin-left: 6px;
}

.field-description {
  font-style: italic;
  /* font-variant: small-caps; */
}

.field-description:before {
  font-family: "Font Awesome 5 Free";
  content: "\f129";
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  font-weight: 900;
  font-style: normal;
}
