body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #23282c;
  text-align: left;
}

.app,
app-dashboard,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  width: 98%;
}

.App-logo {
  height: 4vmin;
  min-height: 2em;
  max-height: 6em;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 60s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: left;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-header-text {
  font-size: calc(8px + 1vmin);
  color: white;
}

.navbartoggler {
  background-color: white;
  color: black;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.visible {
  display: inline-block;
}

.header-fixed .app-header {
  position: fixed;
  z-index: 1020;
  width: 100%;
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  left: 0;
  z-index: 20;
}

.Client-logo {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  overflow: hidden;
}
.client-logo-badge {
  background-color: #ffffff;
}
.leftnav {
  background-color: #dfe3e9;
}

.leftnav.acu {
  background-color: #3c1053;
}
.leftnav.uon {
  background-color: #000000;
}
.leftnav.monash {
  background-color: #006dae;
}
.leftnav.utas {
  background-color: #000000;
}

.app-footer,
.app-header,
.aside-menu,
.main,
.sidebar {
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.main {
  margin-top: 12px;
  min-width: 768px;
}

.aside-menu {
  margin-top: 12px;
  margin-right: 8px;
}
.listGroupHeading {
  margin-left: 8px;
  font-size: medium;
}
.listGroup {
  background-color: #3b4249;
}
.listGroup-aside {
  background-color: #fffed6;
  min-width: 180px;
  max-width: 280px;
}

.collpaseCard {
  margin: 0px;
  padding: 0px;
}
.collapseHeader {
  font-size: 16px;
  cursor: pointer; /*hand*/
  margin-left: 0px;
  padding-left: 4px;
  margin-bottom: 2px;
  margin-top: 0px;
  background-color: #3b4249;
}
.collapseIcon {
  font-size: 10px;
}
.collapseLink {
  color: #c8ced3;
  margin-left: 15px;
}
.collapseLink:hover {
  color: white;
  margin-left: 15px;
}

.app-header {
  flex: 0 0 55px;
}

.app-header {
  position: relative;
  flex-direction: row;
  height: 55px;
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.navbar,
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar {
  /*position: relative;
    padding: .5rem 1rem;*/
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

*,
:after,
:before {
  box-sizing: border-box;
}

header {
  display: block;
}

.app-footer {
  flex: 0 0 50px;
}

.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
  color: #23282c;
  background: #f0f3f5;
  border-top: 1px solid #c8ced3;
  margin-top: 20px;
}

dl,
ol,
ul {
  margin-top: 0;
}

li {
  display: list-item;
}

.sidebar {
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  color: #fff;
  background: #2f353a;
}

.sidebar .sidebar-nav {
  position: relative;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
}

.scrollbar-container {
  position: relative;
  height: 100%;
}

.ps {
  overflow: hidden !important;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.sidebar-nav {
  transition: width 0.25s;
}

.sidebar .nav-link {
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  background: 0 0;
}

.sidebar .nav-title {
  padding: 0.75rem 1rem;
  font-size: 80%;
  font-weight: 700;
  color: #e4e7ea;
  text-transform: uppercase;
}

.array-item {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
}

.form-group.field.field-object {
  width: 600px;
}

/*.array-item-toolbox {
    height: 30px;
}*/
.array-item-remove::before {
  font-size: 12px;
  vertical-align: top;
  content: "Delete";
}
.array-item-remove {
  height: 25px;
  min-width: 75px;
}
.btn-add::before {
  font-size: 12px;
  vertical-align: top;
  content: "Add New";
}
.array-item-add {
  margin-left: 15px;
}
.array-item-move-up::before {
  font-size: 12px;
  vertical-align: top;
  content: "Move Up";
}
.array-item-move-up {
  height: 25px;
  background-color: silver;
}
.array-item-move-down::before {
  font-size: 12px;
  vertical-align: top;
  content: "Move Down";
}
.array-item-move-down {
  height: 25px;
  background-color: silver;
}
.btn-add {
  height: 30px;
  min-width: 75px;
}

.error {
  color: red;
}
